<template>
  <form class="search" @submit.prevent="search">
    <div class="search-icon-container" :class="smallClass">
      <search-icon class="search-icon is-unselectable" style="max-width: 32px" :class="smallClass"></search-icon>
    </div>
    <input type="text" :style="inputStyle" class="input is-search" :class="smallClass" :placeholder="placeholder" v-model="searchValue" />
    <button v-if="buttonVisible" class="button is-rounded is-search is-link has-text-weight-bold" :class="smallClass" :style="inputStyle">
      {{ $t("hints.search") }}
    </button>
  </form>
</template>

<script>
import SearchIcon from "@/assets/icons/search2.svg";
import { debounce } from "@/shared/utils";

export default {
  name: "SearchInput",

  components: {
    SearchIcon,
  },

  data() {
    return {
      searchValue: "",
    };
  },

  props: {
    value: {
      type: String,
    },

    placeholder: {
      type: String,
    },

    buttonVisible: {
      type: Boolean,
      default: true,
    },

    borderRadius: {
      type: String,
    },

    isSmall: {
      type: Boolean,
      default: false,
    },

    isTextDark: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    inputStyle() {
      let styles = {
        borderRadius: this.borderRadius,
      };
      if (this.isTextDark) {
        styles.color = "#000000";
      }
      return styles;
    },

    smallClass() {
      if (this.isSmall) {
        return "is-small";
      } else {
        return "";
      }
    },
  },

  methods: {
    search() {
      this.$emit("input", this.searchValue);
    },
  },

  watch: {
    searchValue: debounce(function (newValue) {
      if (newValue !== this.value) {
        this.$emit("input", newValue);
      }
    }, 500),

    value: {
      handler: function (newValue) {
        this.searchValue = newValue;
      },
    },
  },
};
</script>
